import styled from 'styled-components';

export const Container = styled.div`
    p {
        font-size: 13px;
        color: #b8b8d4;
    }
    h1 {
        margin: 0;
        padding: 0;
        font-size: 26px;
    }
    hr {
        height: 1px;
        border: 0;
        background-color: #16195c;
        margin: 30px 0;
    }

    label {
        font-size: 13px;
        display: block;
        margin-bottom: 20px;

        input {
            display: block;
            margin-top: 7px;
            box-sizing: border-box;
            width: 100%;
            padding: 20px 10px;
            border: 2px solid #0eb8b7;
            border-radius: 10px;
            color: #fff;
            outline: 0;
            font-size: 15px;
            background-color: #02044a;
        }
    }

    button {
        background-color: #0eb8b7;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 40px;
        border: 0;
        border-radius: 30px;
        cursor: pointer;
        margin-top: 30px;

        &:disabled {
            background: #02044a;
            border: 1px solid #0eb8b7;
            color: #0eb8b7;
            cursor: no-drop;
        }
    }

    .backButton {
        display: inline-block;
        font-size: 16px;
        text-decoration: none;
        padding: 20px 40px;
        color: #b8b8d4;
    }
`;

export const CreateContainerSpecialization = styled.div`
    border-radius: 10px;

    button {
        border: 1px solid #0eb8b7;
        background-color: transparent;
        margin-top: 25px;
        padding: 10px 20px;
    }

    .cancel {
        border: 1px solid #d73e3e;
        background-color: #d73e3e;
        margin-top: 25px;
        padding: 10px 20px;
    }

    .container {
        display: flex;
        justify-content: space-between;

        div {
            flex: 0.48;

            label {
                font-weight: bold;
                margin-bottom: 10px;
            }

            input {
                display: block;
                margin-top: 11px;
                box-sizing: border-box;
                width: 100%;
                padding: 16px 25px;
                border: 2px solid #0eb8b7;
                border-radius: 10px;
                color: #fff;
                outline: 0;
                font-size: 13px;
                background-color: #02044a;
                width: 100%;
                -webkit-appaearance: none;

                &::placeholder {
                    color: #fff;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    color: #fff;
                }

                &::-ms-input-placeholder {
                    color: #fff;
                }
            }

            select {
                display: block;
                margin-top: 11px;
                box-sizing: border-box;
                width: 100%;
                padding: 16px 25px;
                border: 2px solid #0eb8b7;
                border-radius: 10px;
                color: #fff;
                outline: 0;
                font-size: 13px;
                background-color: #02044a;
                width: 100%;
                -webkit-appaearance: none;

                &::placeholder {
                    color: #fff;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    color: #fff;
                }

                &::-ms-input-placeholder {
                    color: #fff;
                }
            }

            input[type='file'] {
                display: block;
                margin-top: 11px;
                box-sizing: border-box;
                width: 100%;
                padding: 15px 25px;
                border: 2px solid #0eb8b7;
                border-radius: 10px;
                color: #fff;
                outline: 0;
                font-size: 13px;
                background-color: #02044a;
                width: 100%;
                -webkit-appaearance: none;
            }
        }
    }
`;

export const ListContainerSpecializations = styled.div`
    .create {
        border: 1px solid #0eb8b7;
        background-color: transparent;
        margin-top: 0;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            display: flex;
            border: 2px solid #16195c;
            border-radius: 10px;
            padding: 20px;
            margin-bottom: 15px;
            align-items: center;
            cursor: pointer;

            p {
                margin: 0 0 9px 0;
                padding: 0;
            }

            &:hover {
                border: 2px solid #496459;
            }

            button {
                background-color: transparent;
                padding: 0;
                border: 0;
                cursor: pointer;
                margin: 0;
            }
        }
    }
`;
