// Context, Reducer, Provider, Hook
import { createContext, ReactNode, useContext, useReducer } from 'react';

type State = {
    currentStep: number;
    name: string;
    birth_date: string;
    unit: string;
    shift: string;
    telephone: string;
    mail: string;
    nurse: 0 | 1 | null;
    coren: string;
    course: 0 | 1 | null;
    courses: any[];
    specialization: 0 | 1 | null;
    specializations: any[];
    master: 0 | 1 | null;
    masters: any[];
    doctor: 0 | 1 | null;
    doctors: any[];
    experience: 0 | 1 | null;
    experiences: any[];
    training: 0 | 1 | null;
    trainings: any[];
};

type Action = {
    type: FormActions;
    payload: any;
};

type ContextType = {
    state: State;
    dispatch: (action: Action) => void;
};

type FormProviderProps = {
    children: ReactNode;
};

const initialData: State = {
    currentStep: 0,
    name: '',
    birth_date: '',
    unit: '',
    shift: '',
    telephone: '',
    mail: '',
    nurse: null,
    coren: '',
    course: null,
    courses: [],
    specialization: null,
    specializations: [],
    master: null,
    masters: [],
    doctor: null,
    doctors: [],
    experience: null,
    experiences: [],
    training: null,
    trainings: []
};

// Context
const FormContext = createContext<ContextType | undefined>(undefined);

// Reducer
export enum FormActions {
    setCurrentStep,
    setName,
    setNurse,
    setCoren,
    setMail,
    setBirthDate,
    setTelephone,
    setCourse,
    setCourses,
    setSpecialization,
    setSpecializations,
    setMaster,
    setMasters,
    setDoctor,
    setDoctors,
    setExperience,
    setExperiences,
    setUnit,
    setShift,
    setTraining,
    setTrainings
}

const formReducer = (state: State, action: Action) => {
    switch (action.type) {
        case FormActions.setCurrentStep:
            return { ...state, currentStep: action.payload };
        case FormActions.setName:
            return { ...state, name: action.payload };
        case FormActions.setNurse:
            return { ...state, nurse: action.payload };
        case FormActions.setUnit:
            return { ...state, unit: action.payload };
        case FormActions.setShift:
            return { ...state, shift: action.payload };
        case FormActions.setTelephone:
            return { ...state, telephone: action.payload };
        case FormActions.setCoren:
            return { ...state, coren: action.payload };
        case FormActions.setMail:
            return { ...state, mail: action.payload };
        case FormActions.setBirthDate:
            return { ...state, birth_date: action.payload };
        case FormActions.setCourse:
            return { ...state, course: action.payload };
        case FormActions.setCourses:
            return { ...state, courses: action.payload };
        case FormActions.setSpecialization:
            return { ...state, specialization: action.payload };
        case FormActions.setSpecializations:
            return { ...state, specializations: action.payload };
        case FormActions.setMaster:
            return { ...state, master: action.payload };
        case FormActions.setMasters:
            return { ...state, masters: action.payload };
        case FormActions.setDoctor:
            return { ...state, doctor: action.payload };
        case FormActions.setDoctors:
            return { ...state, doctors: action.payload };
        case FormActions.setExperience:
            return { ...state, experience: action.payload };
        case FormActions.setExperiences:
            return { ...state, experiences: action.payload };
        case FormActions.setTraining:
            return { ...state, training: action.payload };
        case FormActions.setTrainings:
            return { ...state, trainings: action.payload };
        default:
            return state;
    }
};

// Provider
export const FormProvider = ({ children }: FormProviderProps) => {
    const [state, dispatch] = useReducer(formReducer, initialData);
    const value = { state, dispatch };
    return (
        <FormContext.Provider value={value}>{children}</FormContext.Provider>
    );
};

// Context Hook
export const useForm = () => {
    const context = useContext(FormContext);
    if (context === undefined) {
        throw new Error('useForm precisa ser usado dentro do FormProvider');
    }
    return context;
};
