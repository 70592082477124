import { useHistory, Link } from 'react-router-dom';
import * as C from './styles';
import { useForm, FormActions } from '../../contexts/FormContext';
import { Theme } from '../../components/Theme';
import { ChangeEvent, useEffect } from 'react';
import { SelectOption } from '../../components/SelectOption';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const FormStep2 = () => {
    const history = useHistory();
    const { state, dispatch } = useForm();

    useEffect(() => {
        if (
            state.name === '' ||
            state.birth_date === '' ||
            state.mail === '' ||
            state.telephone === '' ||
            state.unit === '' ||
            state.shift === ''
        ) {
            MySwal.fire({
                title: (
                    <p>
                        Por favor, preencha todos os campos da etapa anterior.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });

            history.push('/');
        } else {
            dispatch({
                type: FormActions.setCurrentStep,
                payload: 2
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = () => {
        if (state.nurse !== null) {
            history.push('/step3');
        } else {
            MySwal.fire({
                title: (
                    <p>
                        Verifique os campos, é ncessário que todos estejam
                        preenchidos.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });
        }
    };

    const setNurse = (nurse: number) => {
        dispatch({
            type: FormActions.setNurse,
            payload: nurse
        });
    };

    const setCoren = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActions.setCoren,
            payload: e.target.value
        });
    };

    return (
        <Theme>
            <C.Container>
                <p>Passo 2/8</p>
                <h1>{state.name.split(' ')[0]}, você é enfermeiro?</h1>
                <p>
                    Escolha a opção que melhor condiz com seu estado atual,
                    profissionalmente.
                </p>

                <hr />

                <SelectOption
                    title='Não sou enfermeiro'
                    description='Ainda não sou enfermeiro ou sou formado em outra área.'
                    icon='🧑‍💼'
                    selected={state.nurse === 0}
                    onClick={() => setNurse(0)}
                />

                <SelectOption
                    title='Sou enfermeiro'
                    description='Sim, sou enfermeiro.'
                    icon='👨‍⚕️'
                    selected={state.nurse === 1}
                    onClick={() => setNurse(1)}
                />

                {state.nurse === 1 ? (
                    <label>
                        COREN
                        <input
                            type='text'
                            value={state.coren}
                            onChange={setCoren}
                            placeholder='Digite o seu registro no COREN'
                            required={true}
                        />
                    </label>
                ) : null}

                {state.nurse === 0 || (state.nurse && state.coren !== '') ? (
                    <>
                        <Link to='/' className='backButton'>
                            Voltar
                        </Link>
                        <button onClick={handleNextStep}>Próximo</button>
                    </>
                ) : null}
            </C.Container>
        </Theme>
    );
};
