import styled from 'styled-components';

export const Container = styled.div`
    padding: 25px 0;
    border-bottom: 1px solid #16195c;
    display: flex;
    align-items: center;

    img {
        image-orientation: none;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        img {
            max-width: 200px;
            margin-bottom: 10px;
        }
    }

    div {
        &:last-child {
            border-left: 1px solid;
            padding-left: 20px;
            margin-left: 20px;

            @media (max-width: 768px) {
                border-left: none;
                padding-left: 0;
                margin-left: 0;
            }
        }
    }

    h1 {
        margin: 0;
        padding: 0;
        font-size: 28px;

        @media (max-width: 768px) {
            text-align: center;
        }
    }
    p {
        font-size: 14px;
        color: #b8b8d4;

        @media (max-width: 768px) {
            text-align: center;
        }
    }
`;
