import { ReactNode } from 'react';
import * as C from './styles';
import { Header } from '../Header';
import { SidebarItem } from '../SidebarItem';
import { useForm } from '../../contexts/FormContext';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

type Props = {
    children: ReactNode;
    step?: boolean;
};

export const Theme = ({ children, step = true }: Props) => {
    const { state } = useForm();

    return (
        <C.Container>
            <C.Area>
                <Header />

                <C.StepsMobile centerMode={true} centerSlidePercentage={50}>
                    <SidebarItem
                        title='Pessoal'
                        description='Se identifique'
                        icon='profile'
                        path='/'
                        active={state.currentStep === 1}
                    />

                    <SidebarItem
                        title='Profissional'
                        description='Sua profissão'
                        icon='book'
                        path='/step2'
                        active={state.currentStep === 2}
                    />

                    <SidebarItem
                        title='Especializações'
                        description='Suas especializações'
                        icon='badge'
                        path='/step3'
                        active={state.currentStep === 3}
                    />

                    <SidebarItem
                        title='Mestrado'
                        description='Seus mestrados'
                        icon='mail'
                        path='/step4'
                        active={state.currentStep === 4}
                    />

                    <SidebarItem
                        title='Doutorado'
                        description='Seu doutorado'
                        icon='doctor'
                        path='/step5'
                        active={state.currentStep === 5}
                    />

                    <SidebarItem
                        title='Curso'
                        description='Seus cursos'
                        icon='doctor'
                        path='/step6'
                        active={state.currentStep === 6}
                    />

                    <SidebarItem
                        title='Experiência'
                        description='Suas experiências'
                        icon='certification'
                        path='/step7'
                        active={state.currentStep === 7}
                    />

                    <SidebarItem
                        title='Treinamento'
                        description='Seus treinamentos'
                        icon='certification'
                        path='/step8'
                        active={state.currentStep === 8}
                    />
                </C.StepsMobile>

                <C.Steps>
                    {step ? (
                        <div>
                            <C.Sidebar>
                                <SidebarItem
                                    title='Pessoal'
                                    description='Se identifique'
                                    icon='profile'
                                    path='/'
                                    active={state.currentStep === 1}
                                />

                                <SidebarItem
                                    title='Profissional'
                                    description='Sua profissão'
                                    icon='book'
                                    path='/step2'
                                    active={state.currentStep === 2}
                                />

                                <SidebarItem
                                    title='Especializações'
                                    description='Suas especializações'
                                    icon='badge'
                                    path='/step3'
                                    active={state.currentStep === 3}
                                />

                                <SidebarItem
                                    title='Mestrado'
                                    description='Seus mestrados'
                                    icon='mail'
                                    path='/step4'
                                    active={state.currentStep === 4}
                                />

                                <SidebarItem
                                    title='Doutorado'
                                    description='Seu doutorado'
                                    icon='doctor'
                                    path='/step5'
                                    active={state.currentStep === 5}
                                />

                                <SidebarItem
                                    title='Curso'
                                    description='Seus cursos'
                                    icon='doctor'
                                    path='/step6'
                                    active={state.currentStep === 6}
                                />

                                <SidebarItem
                                    title='Experiência'
                                    description='Suas experiências'
                                    icon='certification'
                                    path='/step7'
                                    active={state.currentStep === 7}
                                />

                                <SidebarItem
                                    title='Treinamento'
                                    description='Seus treinamentos'
                                    icon='certification'
                                    path='/step8'
                                    active={state.currentStep === 8}
                                />
                            </C.Sidebar>
                        </div>
                    ) : null}
                    <C.Page>{children}</C.Page>
                </C.Steps>
            </C.Area>
        </C.Container>
    );
};
