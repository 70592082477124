import * as C from './styles';
import { Theme } from '../../components/Theme';

export const Loading = () => {
    return (
        <Theme step={false}>
            <C.Container>
                <h1>Aguarde um momento</h1>
                <p>
                    Estamos enviando os seus documentos e atualizando o seu
                    cadastro!
                </p>
            </C.Container>
        </Theme>
    );
};
