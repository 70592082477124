import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as C from './styles';
import { useForm, FormActions } from '../../contexts/FormContext';
import { Theme } from '../../components/Theme';
import { SelectOption } from '../../components/SelectOption';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const FormStep4 = () => {
    const history = useHistory();
    const { state, dispatch } = useForm();
    const [create, setCreate] = useState(false);

    const [specializationInput, setSpecializationInput] = useState('');
    const [specializationCertificate, setSpecializationCertificate] =
        useState(null);

    useEffect(() => {
        if (state.specialization === null) {
            MySwal.fire({
                title: (
                    <p>Por favor, selecione se você possui especialização.</p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });

            history.push('/step3');
        } else {
            dispatch({
                type: FormActions.setCurrentStep,
                payload: 4
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = () => {
        if (state.master !== null) {
            history.push('/step5');
        } else {
            MySwal.fire({
                title: (
                    <p>
                        Verifique os campos, é ncessário que todos estejam
                        preenchidos.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });
        }
    };

    const setMaster = (master: number) => {
        dispatch({
            type: FormActions.setMaster,
            payload: master
        });
    };

    const removeSpecialization = (spec: any) => {
        let specializationsArray = state.masters;

        const index = specializationsArray.indexOf(spec);

        if (index > -1) {
            specializationsArray.splice(index, 1);
        }

        dispatch({
            type: FormActions.setMasters,
            payload: specializationsArray
        });
    };

    const createContainerSpecialization = () => {
        let specializationsArray = state.masters;

        specializationsArray.push({
            course: specializationInput,
            file: specializationCertificate
        });

        dispatch({
            type: FormActions.setMasters,
            payload: specializationsArray
        });

        setSpecializationInput('');
        setSpecializationCertificate(null);

        setCreate(false);
    };

    return (
        <Theme>
            <C.Container>
                <p>Passo 3/8</p>
                <h1>
                    {state.name.split(' ')[0]}, quais são os seus mestrados?
                </h1>
                <p>
                    Preencha os seus mestrados e anexe o respectivo certificado.
                </p>

                <hr />

                <SelectOption
                    title='Eu não possuo mestrado'
                    description='Ainda não possuo uma mestrado.'
                    selected={state.master === 0}
                    onClick={() => setMaster(0)}
                />

                <SelectOption
                    title='Eu possuo mestrado'
                    description='Sim, eu possuo mestrado.'
                    selected={state.master === 1}
                    onClick={() => setMaster(1)}
                />

                {state.master ? (
                    <div>
                        {create ? (
                            <C.CreateContainerSpecialization>
                                <h4>Adicionar novo mestrado</h4>
                                <div className='container'>
                                    <div className='specialization-form'>
                                        <label htmlFor='#'>Mestrado</label>
                                        <div className='select-container'>
                                            <input
                                                type='text'
                                                placeholder='Digite o curso do seu mestrado'
                                                onChange={(e: any) =>
                                                    setSpecializationInput(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor='#'>Certificado</label>
                                        <input
                                            type='file'
                                            onChange={(e: any) => {
                                                setSpecializationCertificate(
                                                    e.target.files[0]
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <button
                                        className='cancel'
                                        onClick={() => setCreate(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={() =>
                                            createContainerSpecialization()
                                        }
                                    >
                                        Adicionar
                                    </button>
                                </div>
                            </C.CreateContainerSpecialization>
                        ) : (
                            <C.ListContainerSpecializations>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 25,
                                        alignItems: 'center',
                                        marginBottom: 25
                                    }}
                                >
                                    <h3 style={{ margin: 0, padding: 0 }}>
                                        Meus mestrados
                                    </h3>
                                    <button
                                        className='create'
                                        onClick={() => setCreate(!create)}
                                    >
                                        Adicionar
                                    </button>
                                </div>
                                <ul>
                                    {state.masters.length ? (
                                        state.masters.map(spec => (
                                            <li>
                                                <div>
                                                    <p>
                                                        <strong>
                                                            {spec.course}
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        {spec.file
                                                            ? 'Certificado anexado'
                                                            : 'Você não anexou o certificado'}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        removeSpecialization(
                                                            spec
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        height='24px'
                                                        viewBox='0 0 24 24'
                                                        width='24px'
                                                        fill='#FFFFFF'
                                                    >
                                                        <path
                                                            d='M0 0h24v24H0V0z'
                                                            fill='none'
                                                        />
                                                        <path d='M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
                                                    </svg>
                                                </button>
                                            </li>
                                        ))
                                    ) : (
                                        <li>Nenhum mestrado cadastrado</li>
                                    )}
                                </ul>
                            </C.ListContainerSpecializations>
                        )}
                    </div>
                ) : null}

                {!create ? (
                    <>
                        {state.master === 1 && state.masters.length > 0 ? (
                            <>
                                <Link to='/step3' className='backButton'>
                                    Voltar
                                </Link>

                                <button onClick={handleNextStep}>
                                    Próximo
                                </button>
                            </>
                        ) : (
                            <>
                                {state.master === 0 ? (
                                    <>
                                        <Link
                                            to='/step3'
                                            className='backButton'
                                        >
                                            Voltar
                                        </Link>

                                        <button onClick={handleNextStep}>
                                            Próximo
                                        </button>
                                    </>
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </C.Container>
        </Theme>
    );
};
