import * as C from './styles';
import { Theme } from '../../components/Theme';

export const FormSuccess = () => {
    return (
        <Theme step={false}>
            <C.Container>
                <h1>Obrigado</h1>
                <p>Todas as suas informações foram atualizadas com sucesso.</p>

                <button
                    onClick={() =>
                        (window.location.href = 'https://pac.segeam.com.br')
                    }
                >
                    Volte para a tela principal
                </button>
            </C.Container>
        </Theme>
    );
};
