import { useHistory } from 'react-router-dom';
import * as C from './styles';
import { useForm, FormActions } from '../../contexts/FormContext';
import { Theme } from '../../components/Theme';
import { ChangeEvent, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../../services/api';

const MySwal = withReactContent(Swal);

export const FormStep1 = () => {
    const history = useHistory();
    const { state, dispatch } = useForm();

    useEffect(() => {
        if (history.location.pathname.length > 1) {
            api.get(`/${history.location.pathname.replace('/', '')}`)
                .then((res: any) => {
                    dispatch({
                        type: FormActions.setName,
                        payload: res.data[0].answer
                    });

                    dispatch({
                        type: FormActions.setMail,
                        payload: res.data[5].answer
                    });

                    dispatch({
                        type: FormActions.setBirthDate,
                        payload: res.data[1].answer
                    });

                    dispatch({
                        type: FormActions.setUnit,
                        payload: res.data[2].answer
                    });

                    dispatch({
                        type: FormActions.setShift,
                        payload: res.data[3].answer
                    });

                    dispatch({
                        type: FormActions.setTelephone,
                        payload: res.data[4].answer
                    });

                    if (res.data[6].answer) {
                        dispatch({
                            type: FormActions.setNurse,
                            payload: 1
                        });

                        dispatch({
                            type: FormActions.setCoren,
                            payload: res.data[6].answer
                        });
                    }

                    history.push('/');
                })
                .catch(err => {});
        }

        dispatch({
            type: FormActions.setCurrentStep,
            payload: 1
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = () => {
        if (
            state.name !== '' &&
            state.birth_date !== '' &&
            state.mail !== '' &&
            state.telephone !== '' &&
            state.unit !== '' &&
            state.shift !== ''
        ) {
            history.push('/step2');
        } else {
            MySwal.fire({
                title: (
                    <p>
                        Verifique os campos, é ncessário que todos estejam
                        preenchidos.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });
        }
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActions.setName,
            payload: e.target.value
        });
    };

    const handleMailChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActions.setMail,
            payload: e.target.value
        });
    };

    const handleBirthDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActions.setBirthDate,
            payload: e.target.value
        });
    };

    const handleTelephoneChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActions.setTelephone,
            payload: e.target.value
        });
    };

    const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: FormActions.setUnit,
            payload: e.target.value
        });
    };

    const handleShiftChange = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch({
            type: FormActions.setShift,
            payload: e.target.value
        });
    };

    return (
        <Theme>
            <C.Container>
                <p>Passo 1/8</p>
                <h1>Vamos começar com os seus dados principais</h1>
                <p>Preencha o campo abaixo com seu nome completo.</p>

                <hr />

                <label>
                    Seu nome completo
                    <input
                        type='text'
                        value={state.name}
                        onChange={handleNameChange}
                        placeholder='Digite o seu nome completo'
                        required={true}
                    />
                </label>

                <label>
                    Sua data de nascimento
                    <InputMask
                        mask='99/99/9999'
                        value={state.birth_date}
                        onChange={handleBirthDateChange}
                        placeholder='Digite a sua data de nascimento'
                    />
                </label>

                <label>
                    Sua unidade
                    <select onChange={handleUnitChange} value={state.unit}>
                        <option value=''>Selecione a sua unidade</option>
                        <option value='PSM 28 DE AGOSTO'>
                            PSM 28 DE AGOSTO
                        </option>
                        <option value='PLATÃO ARAÚJO'>PLATÃO ARAÚJO</option>
                        <option value='CAPS DR. SILVERIO TUNDIS (ROSA BLAYA)'>
                            CAPS DR. SILVERIO TUNDIS (ROSA BLAYA)
                        </option>
                        <option value='H GERAL GERALDO DA ROCHA'>
                            H GERAL GERALDO DA ROCHA
                        </option>
                        <option value='HPS JOÃO LÚCIO'>HPS JOÃO LÚCIO</option>
                        <option value='HPSC ZONA LESTE'>HPSC ZONA LESTE</option>
                        <option value='HPSC ZONA OESTE'>HPSC ZONA OESTE</option>
                        <option value='INSTITUTO DA MULHER DONA LINDU'>
                            INSTITUTO DA MULHER DONA LINDU
                        </option>
                        <option value='MATERNIDADE ALVORADA'>
                            MATERNIDADE ALVORADA
                        </option>
                        <option value='MATERNIDADE ANA BRAGA'>
                            MATERNIDADE ANA BRAGA
                        </option>
                        <option value='MATERNIDADE BALBINA MESTRINHO'>
                            MATERNIDADE BALBINA MESTRINHO
                        </option>
                        <option value='MATERNIDADE CHAPOT PREVOST'>
                            MATERNIDADE CHAPOT PREVOST
                        </option>
                        <option value='MATERNIDADE NAZIRA DAOU'>
                            MATERNIDADE NAZIRA DAOU
                        </option>
                        <option value='PÉ DIABÉTICO - PAM CODAJÁS'>
                            PÉ DIABÉTICO-PAM CODAJÁS
                        </option>
                        <option value='PÉ DIABÉTICO - POLICLÍNICA PAM CENTRO'>
                            PÉ DIABÉTICO-POLICLÍNICA PAM CENTRO
                        </option>
                        <option value='PÉ DIABETICO - ZENO LANZINE'>
                            PÉ DIABETICO-ZENO LANZINE
                        </option>
                        <option value='PÉ DIABÉTICO - DANILO CORREA'>
                            PÉ DIABÉTICO-DANILO CORREA
                        </option>
                        <option value='PÉ DIABETICO - JOSÉ LINS'>
                            PÉ DIABETICO-JOSÉ LINS
                        </option>
                        <option value='PS DA CRIANCA ZONA SUL'>
                            PS DA CRIANCA ZONA SUL
                        </option>
                        <option value='SEDE ADMINISTRATIVA'>
                            SEDE ADMINISTRATIVA
                        </option>
                        <option value='SESI'>SESI</option>
                        <option value='SPA CHAPOT PREVOST'>
                            SPA CHAPOT PREVOST
                        </option>
                        <option value='SPA COROADO'>SPA COROADO</option>
                        <option value='SPA JOSE LINS'>SPA JOSE LINS</option>
                        <option value='SPA JOVENTINA DIAS'>
                            SPA JOVENTINA DIAS
                        </option>
                        <option value='SPA SAO RAIMUNDO'>
                            SPA SAO RAIMUNDO
                        </option>
                        <option value='SPA ZONA SUL'>SPA ZONA SUL</option>
                    </select>
                </label>

                <label>
                    Seu turno
                    <select onChange={handleShiftChange} value={state.shift}>
                        <option value=''>Selecione o seu turno</option>
                        <option value='DIURNO'>DIURNO</option>
                        <option value='NOTURNO'>NOTURNO</option>
                        <option value='COMERCIAL'>COMERCIAL</option>
                    </select>
                </label>

                <label>
                    Seu telefone
                    <InputMask
                        mask='(99) 99999-9999'
                        value={state.telephone}
                        onChange={handleTelephoneChange}
                        placeholder='Digite o seu telefone'
                        required={true}
                    />
                    {/* <input
                        type='text'
                        value={state.telephone}
                        onChange={handleTelephoneChange}
                        placeholder='Digite o seu telefone'
                        required={true}
                    /> */}
                </label>

                <label>
                    Seu e-mail
                    <input
                        type='text'
                        value={state.mail}
                        onChange={handleMailChange}
                        placeholder='Digite o seu e-mail'
                        required={true}
                    />
                </label>

                <button onClick={handleNextStep}>Próximo</button>
            </C.Container>
        </Theme>
    );
};
