import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as C from './styles';
import { useForm, FormActions } from '../../contexts/FormContext';
import { Theme } from '../../components/Theme';
import { SelectOption } from '../../components/SelectOption';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const FormStep6 = () => {
    const history = useHistory();
    const { state, dispatch } = useForm();
    const [create, setCreate] = useState(false);
    const [other, setOther] = useState('');

    const [specializationInput, setSpecializationInput] = useState('');
    const [specializationCertificate, setSpecializationCertificate] =
        useState(null);

    useEffect(() => {
        if (state.doctor === null) {
            MySwal.fire({
                title: <p>Por favor, selecione se você possui doutorado.</p>,
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });

            history.push('/step5');
        } else {
            dispatch({
                type: FormActions.setCurrentStep,
                payload: 6
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = () => {
        if (state.course !== null) {
            history.push('/step7');
        } else {
            MySwal.fire({
                title: (
                    <p>
                        Verifique os campos, é ncessário que todos estejam
                        preenchidos.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });
        }
    };

    const setCourse = (course: number) => {
        dispatch({
            type: FormActions.setCourse,
            payload: course
        });
    };

    const removeSpecialization = (spec: any) => {
        let specializationsArray = state.courses;

        const index = specializationsArray.indexOf(spec);

        if (index > -1) {
            specializationsArray.splice(index, 1);
        }

        dispatch({
            type: FormActions.setCourses,
            payload: specializationsArray
        });
    };

    const createContainerSpecialization = () => {
        let specializationsArray = state.courses;

        specializationsArray.push({
            course: other !== '' ? other : specializationInput,
            file: specializationCertificate
        });

        dispatch({
            type: FormActions.setCourses,
            payload: specializationsArray
        });

        setSpecializationInput('');
        setSpecializationCertificate(null);
        setOther('');

        setCreate(false);
    };

    return (
        <Theme>
            <C.Container>
                <p>Passo 3/8</p>
                <h1>{state.name.split(' ')[0]}, quais são os seus cursos?</h1>
                <p>Preencha os seus cursos e anexe o respectivo certificado.</p>

                <hr />

                <SelectOption
                    title='Eu não possuo cursos'
                    description='Ainda não possuo cursos.'
                    selected={state.course === 0}
                    onClick={() => setCourse(0)}
                />

                <SelectOption
                    title='Eu possuo cursos'
                    description='Sim, eu possuo cursos.'
                    selected={state.course === 1}
                    onClick={() => setCourse(1)}
                />

                {state.course ? (
                    <div>
                        {create ? (
                            <C.CreateContainerSpecialization>
                                <h4>Adicionar novo curso</h4>
                                <div
                                    className='container'
                                    style={{
                                        flexDirection:
                                            specializationInput === 'other'
                                                ? 'column'
                                                : 'row'
                                    }}
                                >
                                    <div className='specialization-form'>
                                        <label htmlFor='#'>Curso</label>
                                        <div className='select-container'>
                                            <select
                                                onChange={(e: any) => {
                                                    setSpecializationInput(
                                                        e.target.value
                                                    );

                                                    if (
                                                        e.target.value !==
                                                        'other'
                                                    ) {
                                                        setOther('');
                                                    }
                                                }}
                                            >
                                                <option value=''>
                                                    Selecione o curso
                                                </option>
                                                <option value='ATLS'>
                                                    ATLS
                                                </option>
                                                <option value='ACLS'>
                                                    ACLS
                                                </option>
                                                <option value='PHTLS'>
                                                    PHTLS
                                                </option>
                                                <option value='PALS'>
                                                    PALS
                                                </option>
                                                <option value='SBV'>SBV</option>
                                                <option value='ATCN'>
                                                    ATCN
                                                </option>
                                                <option value='ACR em Obstetrícia'>
                                                    ACR em Obstetrícia
                                                </option>
                                                <option value='IHAC'>
                                                    IHAC
                                                </option>
                                                <option value='Método Canguru'>
                                                    Método Canguru
                                                </option>
                                                <option value='Reanimação Neonatal'>
                                                    Reanimação Neonatal
                                                </option>
                                                <option
                                                    value='Classificação Manchester
                                                    (Grupo Brasileiro de
                                                    Classificação de Risco)'
                                                >
                                                    Classificação Manchester
                                                    (Grupo Brasileiro de
                                                    Classificação de Risco)
                                                </option>
                                                <option value='ALSO'>
                                                    ALSO
                                                </option>
                                                <option value='other'>
                                                    Outros
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    {specializationInput === 'other' ? (
                                        <div
                                            style={{
                                                marginTop: 15,
                                                marginBottom: 15
                                            }}
                                        >
                                            <label htmlFor='#'>
                                                Digite o nome do curso
                                            </label>
                                            <input
                                                onChange={(e: any) =>
                                                    setOther(e.target.value)
                                                }
                                                type='text'
                                                placeholder='Qual? Digite o nome do curso'
                                            />
                                        </div>
                                    ) : null}
                                    <div>
                                        <label htmlFor='#'>Certificado</label>
                                        <input
                                            type='file'
                                            onChange={(e: any) => {
                                                setSpecializationCertificate(
                                                    e.target.files[0]
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <button
                                        className='cancel'
                                        onClick={() => setCreate(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={() =>
                                            createContainerSpecialization()
                                        }
                                    >
                                        Adicionar
                                    </button>
                                </div>
                            </C.CreateContainerSpecialization>
                        ) : (
                            <C.ListContainerSpecializations>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 25,
                                        alignItems: 'center',
                                        marginBottom: 25
                                    }}
                                >
                                    <h3 style={{ margin: 0, padding: 0 }}>
                                        Meus cursos
                                    </h3>
                                    <button
                                        className='create'
                                        onClick={() => setCreate(!create)}
                                    >
                                        Adicionar
                                    </button>
                                </div>
                                <ul>
                                    {state.courses.length ? (
                                        state.courses.map(spec => (
                                            <li>
                                                <div>
                                                    <p>
                                                        <strong>
                                                            {spec.course}
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        {spec.file
                                                            ? 'Certificado anexado'
                                                            : 'Você não anexou o certificado'}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        removeSpecialization(
                                                            spec
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        height='24px'
                                                        viewBox='0 0 24 24'
                                                        width='24px'
                                                        fill='#FFFFFF'
                                                    >
                                                        <path
                                                            d='M0 0h24v24H0V0z'
                                                            fill='none'
                                                        />
                                                        <path d='M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
                                                    </svg>
                                                </button>
                                            </li>
                                        ))
                                    ) : (
                                        <li>Nenhum curso cadastrado</li>
                                    )}
                                </ul>
                            </C.ListContainerSpecializations>
                        )}
                    </div>
                ) : null}

                {!create ? (
                    <>
                        {state.course === 1 && state.courses.length > 0 ? (
                            <>
                                <Link to='/step2' className='backButton'>
                                    Voltar
                                </Link>

                                <button onClick={handleNextStep}>
                                    Próximo
                                </button>
                            </>
                        ) : (
                            <>
                                {state.course === 0 ? (
                                    <>
                                        <Link
                                            to='/step5'
                                            className='backButton'
                                        >
                                            Voltar
                                        </Link>

                                        <button onClick={handleNextStep}>
                                            Próximo
                                        </button>
                                    </>
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </C.Container>
        </Theme>
    );
};
