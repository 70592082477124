import { Link } from 'react-router-dom';
import * as C from './styles';
import { ReactComponent as ProfileIcon } from '../../svgs/profile.svg';

type Props = {
    title: string;
    description: string;
    icon: string;
    path: string;
    active: boolean;
};

export const SidebarItem = ({
    title,
    description,
    icon,
    path,
    active
}: Props) => {
    return (
        <C.Container>
            <Link to={path}>
                <C.Info>
                    <C.Title active={active}>{title}</C.Title>
                    <C.Description>{description}</C.Description>
                </C.Info>
                <C.IconArea active={active}>
                    {icon === 'profile' && (
                        <ProfileIcon fill='white' width={24} height={24} />
                    )}
                    {icon === 'book' && (
                        <img
                            src={
                                require('../../assets/images/nurse.png').default
                            }
                            alt=''
                            width={24}
                            height={24}
                        />
                    )}
                    {icon === 'mail' && (
                        <img
                            src={
                                require('../../assets/images/quality.png')
                                    .default
                            }
                            alt=''
                            width={24}
                            height={24}
                        />
                    )}
                    {icon === 'badge' && (
                        <img
                            src={
                                require('../../assets/images/badge.png').default
                            }
                            alt=''
                            width={24}
                            height={24}
                        />
                    )}
                    {icon === 'doctor' && (
                        <img
                            src={
                                require('../../assets/images/doctor.png')
                                    .default
                            }
                            alt=''
                            width={24}
                            height={24}
                        />
                    )}
                    {icon === 'certification' && (
                        <img
                            src={
                                require('../../assets/images/certification.png')
                                    .default
                            }
                            alt=''
                            width={24}
                            height={24}
                        />
                    )}
                </C.IconArea>
                <C.Point active={active}></C.Point>
            </Link>
        </C.Container>
    );
};
