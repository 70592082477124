import { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as C from './styles';
import { useForm, FormActions } from '../../contexts/FormContext';
import { Theme } from '../../components/Theme';
import { SelectOption } from '../../components/SelectOption';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import api from '../../services/api';
import { Loading } from '../../components/Loading';
const MySwal = withReactContent(Swal);

export const FormStep8 = () => {
    const history = useHistory();
    const { state, dispatch } = useForm();
    const [create, setCreate] = useState(false);

    const [specializationInput, setSpecializationInput] = useState('');
    const [specializationCertificate, setSpecializationCertificate] =
        useState(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [other, setOther] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (state.experience === null) {
            MySwal.fire({
                title: (
                    <p>
                        Por favor, selecione se você possui experiências
                        anteriores.
                    </p>
                ),
                footer: 'Copyright 2021 - SEGEAM',
                confirmButtonText: 'Sair',
                icon: 'error'
            });

            history.push('/step7');
        } else {
            dispatch({
                type: FormActions.setCurrentStep,
                payload: 8
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = async () => {
        if (state.mail !== '') {
            let data = new FormData();
            let t: any = state;

            // eslint-disable-next-line array-callback-return
            Object.keys(state).map(async (key: any) => {
                if (key === 'specializations') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.course}]`, e.file);
                    });
                } else if (key === 'masters') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.course}]`, e.file);
                    });
                } else if (key === 'doctors') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.doctor}]`, e.file);
                    });
                } else if (key === 'courses') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.course}]`, e.file);
                    });
                } else if (key === 'experiences') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.experience}]`, e.time);
                    });
                } else if (key === 'trainings') {
                    // eslint-disable-next-line array-callback-return
                    t[key].map((e: any) => {
                        data.append(key + `[${e.training}]`, e.file);
                    });
                } else {
                    data.append(key, t[key]);
                }
            });

            setLoading(true);

            await api
                .post('/save/atualizacao-cadastral', data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    MySwal.fire({
                        title: (
                            <p>
                                Obrigado, suas informações foram atualizadas com
                                sucesso!
                            </p>
                        ),
                        footer: 'Copyright 2021 - SEGEAM',
                        confirmButtonText: 'Sair',
                        icon: 'success'
                    }).then(() => {
                        setLoading(false);
                        history.push('/success');
                    });
                })
                .catch(err => {
                    setLoading(false);
                    MySwal.fire({
                        title: (
                            <p>
                                Não foi possível enviar as suas informações,
                                verifique os campos preenchidos.
                            </p>
                        ),
                        footer: 'Copyright 2021 - SEGEAM',
                        confirmButtonText: 'Sair',
                        icon: 'error'
                    });
                });
        } else {
            alert('Preencha os dados');
        }
    };

    const setTraining = (training: number) => {
        dispatch({
            type: FormActions.setTraining,
            payload: training
        });
    };

    const removeSpecialization = (spec: any) => {
        let specializationsArray = state.trainings;

        const index = specializationsArray.indexOf(spec);

        if (index > -1) {
            specializationsArray.splice(index, 1);
        }

        dispatch({
            type: FormActions.setTrainings,
            payload: specializationsArray
        });
    };

    const createContainerSpecialization = () => {
        let specializationsArray = state.trainings;

        specializationsArray.push({
            training: other !== '' ? other : specializationInput,
            file: specializationCertificate
        });

        dispatch({
            type: FormActions.setTrainings,
            payload: specializationsArray
        });

        setSpecializationInput('');
        setSpecializationCertificate(null);

        setCreate(false);
        setOther('');
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Theme>
            <C.Container>
                <p>Passo 8/8</p>
                <h1>
                    {state.name.split(' ')[0]}, quais são os seus treinamentos?
                </h1>
                <p>
                    Preencha os seus treinamentos e anexe o respectivo
                    certificado.
                </p>

                <hr />

                <SelectOption
                    title='Eu não possuo treinamento'
                    description='Ainda não possuo treinamento.'
                    selected={state.training === 0}
                    onClick={() => setTraining(0)}
                />

                <SelectOption
                    title='Eu possuo treinamento'
                    description='Sim, eu possuo treinamento.'
                    selected={state.training === 1}
                    onClick={() => setTraining(1)}
                />

                {state.training ? (
                    <div>
                        {create ? (
                            <C.CreateContainerSpecialization>
                                <h4>Adicionar novo treinamento</h4>
                                <div
                                    className='container'
                                    style={{
                                        flexDirection:
                                            specializationInput === 'other'
                                                ? 'column'
                                                : 'row'
                                    }}
                                >
                                    <div className='specialization-form'>
                                        <div className='select-container'>
                                            <label htmlFor='#'>
                                                Digite o nome do curso
                                            </label>
                                            <select
                                                onChange={(e: any) => {
                                                    setSpecializationInput(
                                                        e.target.value
                                                    );

                                                    if (
                                                        e.target.value !==
                                                        'other'
                                                    ) {
                                                        setOther('');
                                                    }
                                                }}
                                            >
                                                <option value=''>
                                                    Selecione um treinamento
                                                </option>
                                                <option value='Classificação de Risco'>
                                                    Classificação de Risco
                                                </option>
                                                <option value='ALCON'>
                                                    ALCON
                                                </option>
                                                <option value='Reanimação Cardiopulmonar'>
                                                    Reanimação Cardiopulmonar
                                                </option>
                                                <option value='Atendimento a queimados'>
                                                    Atendimento a queimados
                                                </option>
                                                <option value='Ambulatório de Egressos'>
                                                    Ambulatório de Egressos
                                                </option>
                                                <option value='Prevenção e Tratamento em Lesões da Pele'>
                                                    Prevenção e Tratamento em
                                                    Lesões da Pele
                                                </option>
                                                <option value='Gasometria'>
                                                    Gasometria
                                                </option>
                                                <option value='Interpretação de Exames Laboratoriais'>
                                                    Interpretação de Exames
                                                    Laboratoriais
                                                </option>
                                                <option value='Ventilação Mecânica'>
                                                    Ventilação Mecânica
                                                </option>
                                                <option value='Partograma'>
                                                    Partograma
                                                </option>
                                                <option value='Urgência e Emergência Obstétrica'>
                                                    Urgência e Emergência
                                                    Obstétrica
                                                </option>
                                                <option value='Boas Práticas do Parto e Nascimento'>
                                                    Boas Práticas do Parto e
                                                    Nascimento
                                                </option>
                                                <option value='Manejo do Períneo'>
                                                    Manejo do Períneo
                                                </option>
                                                <option value='other'>
                                                    Outros
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    {specializationInput === 'other' ? (
                                        <div
                                            style={{
                                                marginTop: 15,
                                                marginBottom: 15
                                            }}
                                        >
                                            <label htmlFor='#'>
                                                Digite o nome do treinamento
                                            </label>
                                            <input
                                                onChange={(e: any) =>
                                                    setOther(e.target.value)
                                                }
                                                type='text'
                                                placeholder='Qual? Digite o nome do treinamento'
                                            />
                                        </div>
                                    ) : null}
                                    <div>
                                        <label htmlFor='#'>Certificado</label>
                                        <input
                                            type='file'
                                            onChange={(e: any) => {
                                                setSpecializationCertificate(
                                                    e.target.files[0]
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <button
                                        className='cancel'
                                        onClick={() => setCreate(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        onClick={() =>
                                            createContainerSpecialization()
                                        }
                                    >
                                        Adicionar
                                    </button>
                                </div>
                            </C.CreateContainerSpecialization>
                        ) : (
                            <C.ListContainerSpecializations>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 25,
                                        alignItems: 'center',
                                        marginBottom: 25
                                    }}
                                >
                                    <h3 style={{ margin: 0, padding: 0 }}>
                                        Meus treinamentos
                                    </h3>
                                    <button
                                        className='create'
                                        onClick={() => setCreate(!create)}
                                    >
                                        Adicionar
                                    </button>
                                </div>
                                <ul>
                                    {state.trainings.length ? (
                                        state.trainings.map(spec => (
                                            <li>
                                                <div>
                                                    <p>
                                                        <strong>
                                                            {spec.training}
                                                        </strong>
                                                    </p>
                                                    <p>
                                                        {spec.file
                                                            ? 'Certificado anexado'
                                                            : 'Você não anexou o certificado'}
                                                    </p>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        removeSpecialization(
                                                            spec
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        height='24px'
                                                        viewBox='0 0 24 24'
                                                        width='24px'
                                                        fill='#FFFFFF'
                                                    >
                                                        <path
                                                            d='M0 0h24v24H0V0z'
                                                            fill='none'
                                                        />
                                                        <path d='M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
                                                    </svg>
                                                </button>
                                            </li>
                                        ))
                                    ) : (
                                        <li>Nenhum treinamento cadastrado</li>
                                    )}
                                </ul>
                            </C.ListContainerSpecializations>
                        )}
                    </div>
                ) : null}

                {!create ? (
                    <>
                        {state.training === 1 && state.trainings.length > 0 ? (
                            <>
                                <Link to='/step2' className='backButton'>
                                    Voltar
                                </Link>

                                <button onClick={handleNextStep}>
                                    Finalizar atualização
                                </button>
                            </>
                        ) : (
                            <>
                                {state.training === 0 ? (
                                    <>
                                        <Link
                                            to='/step6'
                                            className='backButton'
                                        >
                                            Voltar
                                        </Link>

                                        <button onClick={handleNextStep}>
                                            Finalizar atualização
                                        </button>
                                    </>
                                ) : null}
                            </>
                        )}
                    </>
                ) : null}
            </C.Container>
        </Theme>
    );
};
