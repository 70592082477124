import styled from 'styled-components';

export const Container = styled.div`
    text-align: center;

    p {
        font-size: 24px;
        color: #b8b8d4;
        text-align: center;
    }

    h1 {
        margin: 50px 0 0 0;
        padding: 0;
        font-size: 36px;
        text-align: center;
    }

    hr {
        height: 1px;
        border: 0;
        background-color: #16195c;
        margin: 30px 0;
    }

    label {
        font-size: 13px;
        font-weight: bold;

        select {
            display: block;
            margin-top: 11px;
            box-sizing: border-box;
            width: 100%;
            padding: 20px 10px;
            border: 2px solid #0eb8b7;
            border-radius: 10px;
            color: #fff;
            outline: 0;
            font-size: 15px;
            background-color: #02044a;
            margin-bottom: 15px;
            -webkit-appearance: none;

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }

            &::placeholder {
                color: #fff;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                color: #fff;
            }

            &::-ms-input-placeholder {
                color: #fff;
            }
        }

        input {
            display: block;
            margin-top: 11px;
            box-sizing: border-box;
            width: 100%;
            padding: 20px 10px;
            border: 2px solid #0eb8b7;
            border-radius: 10px;
            color: #fff;
            outline: 0;
            font-size: 15px;
            background-color: #02044a;
            margin-bottom: 15px;

            &::-webkit-calendar-picker-indicator {
                filter: invert(1);
            }

            &::placeholder {
                color: #fff;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                color: #fff;
            }

            &::-ms-input-placeholder {
                color: #fff;
            }
        }
    }

    button {
        background-color: #0eb8b7;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 40px;
        border: 0;
        border-radius: 30px;
        cursor: pointer;
        margin-top: 30px;
        margin: 0 auto;
    }
`;
