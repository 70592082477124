import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';

export const Container = styled.div`
    background-color: #02044a;
    color: #fff;
    min-height: 100vh;
`;

export const Area = styled.div`
    margin: auto;
    max-width: 980px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Steps = styled.div`
    flex: 1;
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Sidebar = styled.div`
    width: 250px;
    border-right: 1px solid #16195c;

    @media (max-width: 768px) {
        /* border-right: none;
        display: flex;
        overflow: auto;
        overflow-x: auto;
        width: 100%; */
        display: none;
    }
`;

export const Page = styled.div`
    flex: 1;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: 768px) {
        padding-right: 40px;
    }
`;

export const StepsMobile = styled(Carousel)`
    @media (min-width: 768px) {
        display: none;
    }

    padding: 0;

    .carousel-status {
        display: none;
    }

    .control-dots {
        bottom: -10%;
    }

    .thumbs-wrapper {
        /* display: none; */
    }
`;
