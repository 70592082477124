import * as C from './styles';

export const Header = () => {
    return (
        <C.Container>
            <div>
                <a href='/'>
                    <img
                        src={require('../../assets/images/logo.png').default}
                        alt=''
                        width={140}
                    />
                </a>
            </div>
            <div>
                <h1>Atualização cadastral</h1>
                <p style={{ margin: 0, padding: '5px 0' }}>
                    Faça a sua atualização cadastral rapidamente através do
                    formulário.
                </p>
            </div>
        </C.Container>
    );
};
